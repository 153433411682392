import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';
import DatePicker from '../date-time-picker';
import { GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_PAYMENTLINK_UPDATE_BY_SLUG } from '../../gqls';
import { validator, paymentLinkUrl, sanitizeDOM, REGEX_SLUG, slug } from '../../utils';
import Icon from '../icon';
import { updatePaymentLinks } from '../../actions/payment-links';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import { EditorState } from 'draft-js';
import MultipleImageInput from "../multiple-image-input";

const ModalBodyPaymentLink = ({ isOpen = false, onClose, onSuccess, data }) => {
  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newpl }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: updateLoading, data: upl }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);
  const { register, handleSubmit, errors, control } = useForm();
  const stateEdit = EditorState.createEmpty();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [link, setLink] = useState(data ? data.link : '');
  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const dispatch = useDispatch();
  const isUpdate = !data ? false : true;
  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  if (!data) data = {};

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    if (isUpdate && values.limit) {
      if (data?.limit === null) {
        values.limit = parseInt(values.limit) + data?.paidPaymentLink;
      } else if (data?.limit !== null) {
        values.limit = parseInt(values.limit) + data?.limit;
      }
    } else if (!isUpdate && values.limit) {
      values.limit = parseInt(values.limit);
    } else {
      delete values.limit;
    }

    if (values.amount) values.amount = parseFloat(values.amount);

    if (values.crossoutPrice) values.crossoutPrice = parseFloat(values.crossoutPrice);
    else values.crossoutPrice = null

    if (date !== null) setDate(date);

    if (isUpdate) {
      values.id = data.id;
    }

    const paymentLinkObject = {
      ...values,
      expiredAt: date,
      description: sanitizeDOM(description)
    };

    try {
      if (isUpdate) {
        paymentLinkObject.status = 'active';

        if (multipleImageId) {
          paymentLinkObject.multipleImageId = multipleImageId;
        }
        if (isRemoveAllProductImage) {
          paymentLinkObject.multipleImageId = null;
        }

        await editPaymentLinkBySlug({
          variables: {
            input: paymentLinkObject
          }
        });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        paymentLinkObject.type = 'generic_link';
        paymentLinkObject.link = slug(values.name);
        paymentLinkObject.status = 'active';

        if (multipleImageId) {
          paymentLinkObject.multipleImageId = multipleImageId;
        }

        await insertPaymentLinkBySlug({
          variables: {
            input: paymentLinkObject
          }
        });
        //segment tracking
        window.analytics.track('Create Payment Link', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Payment Link - ERROR', values);
    }
  };

  if (newpl && newpl.insertPaymentLinkBySlug) onSuccess(newpl.insertPaymentLinkBySlug.id);
  if (upl) onClose();

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Link Pembayaran</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>Terima pembayaran dengan mudah dari banyak orang dengan jumlah pembayaran yang sama.</small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Link Pembayaran*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="amount">Harga*</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="amount"
                    id="amount"
                    invalid={errors.amount}
                    defaultValue={data.amount}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1000),
                      max: validator.max(100000000)
                    })}
                  />
                  <FormFeedback>{errors.amount?.message}</FormFeedback>
                </InputGroup>
                <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="crossoutPrice">Harga Coret (opsional)</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="crossoutPrice"
                    id="crossoutPrice"
                    defaultValue={data?.crossoutPrice}
                    invalid={errors.crossoutPrice}
                    innerRef={register({
                      min: validator.min(1000),
                      max: validator.max(100000000)
                    })}
                  />
                  <FormFeedback>{errors.crossoutPrice?.message}</FormFeedback>
                  <small>Harga coret harus lebih besar dari harga utama.</small>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />
          
          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                // rules={{ required: { value: true, message: 'Wajib diisi' } }}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="kadaluarsa4">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <DatePicker
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Pesan setelah bayar / catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Pesan yang akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional), masukkan
              ucapan terima kasih, instruksi join grup wa, atau pesan lainnya disini.{' '}
            </small>
          </FormGroup>
          <FormGroup>
            {isUpdate ? (
              <>
                <Row>
                  <FormGroup className="col-6">
                    <Label for="limit">Kuota Sebelumnya</Label>
                    <Input
                      type="number"
                      placeholder={data?.limit === null ? 'Tidak Terbatas' : Number(data?.limit)}
                      // defaultValue={data?.limit !== null ? data?.limit - data?.paymentLinkPaid : null}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="col-6 pl-0">
                    <Label for="limit">Tambah Kuota</Label>
                    <Input
                      type="number"
                      name="limit"
                      id="limit"
                      className="col-6 mw-100"
                      invalid={Boolean(errors.limit)}
                      innerRef={register({
                        min: validator.min(1),
                        max: validator.max(10_000_000)
                      })}
                    />
                    {/* <small>Minimum </small> */}
                    <FormFeedback>{errors.limit?.message}</FormFeedback>
                  </FormGroup>
                </Row>
              </>
            ) : (
              <>
                <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / Qty)</Label>
                <Input
                  type="number"
                  name="limit"
                  id="limit"
                  invalid={errors.limit}
                  defaultValue={data.limit}
                  innerRef={register()}
                />
                <FormFeedback>{errors?.limit?.message}</FormFeedback>
                <small>
                  Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
                  jumlah (unlimited)
                </small>
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websitesaya.com/pembayaran-sukses"
              invalid={errors.redirectUrl}
              defaultValue={data.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
            <small>Jika diisi, Kami akan membawa pelanggan ke halaman ini setelah sukses membayar</small>
          </FormGroup>
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Link Pembayaran`}
            loading
            isDisabled={disableSubmitButton}
            isLoading={insertLoading || updateLoading}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyPaymentLink;
