import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { EditorState } from 'draft-js';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  CustomInput,
  Row,
  Col
} from 'reactstrap';
import {
  GQL_PAYMENTLINK_CREATE_BY_SLUG,
  GQL_PAYMENTLINK_UPDATE_BY_SLUG,
  GQL_GET_USER_CONTENTS,
  GQL_INSERT_USER_CONTENT_FILE,
  GQL_UPDATE_USER_CONTENT_FILE,
  GQL_GET_CONTENT_FILE,
} from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  sanitizeDOM,
  REGEX_SLUG,
  paymentTypeOptions,
  slug,
  parseJSON,
  licenseTypeOptions
} from '../../utils';
import { updatePaymentLinks } from '../../actions/payment-links';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import Icon from '../icon';
import DatePicker from '../date-time-picker';
import Dropzone from '../dropzone';
import LoadingAnimation from '../loading-animation';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import MayarAlert from '../alert/alert';
import MultipleImageInput from "../multiple-image-input";

/* Please be careful when changing/updating this keys. 
This keys is related to queue-saas and will be validated and used there to determined the total active time of a license code */
const periodOptions = {
  days: 'Hari',
  months: 'Bulan',
  years: 'Tahun'
};

const ModalBodyLicenseCode = ({ isOpen = false, onClose, onSuccess, data }) => {
  if (!isOpen) return null;

  const dispatch = useDispatch();
  const isUpdate = Boolean(data);

  const stateEdit = EditorState.createEmpty();
  const [useRedirect, setUseRedirect] = useState(Boolean(data?.redirectUrl));
  const [sourceFile, setSourceFile] = useState(!data?.redirectUrl ? 'existing' : '');
  const [fileId, setFileId] = useState(!data?.redirectUrl ? data?.content[0]?.file?.id : null);
  const [notAllowed, setNotAllowed] = useState(false);
  const [fileOptions, setfileOptions] = useState([]);
  const [paymentType, setPaymentType] = useState(data?.paymentType || 'paid');

  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [licenseType, setLicenseType] = useState(data?.saasProductInfo?.licenseType || '');
  const activePeriod = parseJSON(data?.saasProductInfo?.activePeriod, [1, 'month']);

  const [isDownloadable, setIsDownloadable] = useState(data?.isDownloadable || false);

  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newpl }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: updateLoading, data: upl }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);
  const [userUserContent, { loading: insertUserContentLoading, data: userContent }] = useMutation(
    GQL_INSERT_USER_CONTENT_FILE
  );
  const [doUpdateUserContent, { loading: updateUserContentLoading, data: updateUserContent }] = useMutation(
    GQL_UPDATE_USER_CONTENT_FILE
  );

  const { data: getUserContent } = useQuery(GQL_GET_CONTENT_FILE, {
    variables: {
      search: {
        key: [{ operator: 'eq', value: data?.id || 'untitle' }]
      }
    },
    fetchPolicy: 'network-only'
  });

  const { loading: loadingFiles, data: dataFiles } = useQuery(GQL_GET_USER_CONTENTS, {
    variables: {
      limit: 100,
      offset: 0
    },
    context: { v2: true }
  });

  const { register, handleSubmit, errors, control } = useForm();

  const [submitError, setSubmitError] = useState({
    status: false,
    message: ''
  });

  const [date, setDate] = useState(data?.expiredAt || null);
  const [startAt, setStartAt] = useState(data?.startAt || null);
  const [link, setLink] = useState(data?.link || '');

  const resetError = () => setSubmitError({ status: false, message: '' });

  if (!data) data = {};

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    let inputActivePeriod = null;

    if (values.activePeriodDate && values.activePeriod) {
      inputActivePeriod = JSON.stringify([parseInt(values.activePeriodDate), values.activePeriod]);
    }

    const activationLimit = parseInt(values.activationLimit);

    delete values.activePeriodDate;
    delete values.activePeriod;
    delete values.activationLimit;
    delete values.startAt;

    if (!licenseType) {
      setSubmitError({
        status: true,
        message: 'Silahkan pilih opsi Tipe Lisensi terlebih dahulu.'
      });
      return;
    }

    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    delete values.fileSource;
    delete values.useRedirect;

    if (!values.limit) {
      delete values.limit;
    } else {
      values.limit = parseInt(values.limit);
    }

    if (values.amount) values.amount = parseFloat(values.amount);

    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) {
      values.amount = 0;
    }

    if (values.crossoutPrice) values.crossoutPrice = parseFloat(values.crossoutPrice);
    else values.crossoutPrice = null

    if (date !== null) setDate(date);

    if (isDownloadable && !useRedirect && !fileId) return;

    try {
      if (isUpdate) {
        values.id = data.id;

        if (!useRedirect || !isDownloadable) values.redirectUrl = '';

        const editPaymentLink = await editPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              ...(isRemoveAllProductImage && { multipleImageId: null }),
              startAt,
              status: 'active',
              expiredAt: date,
              description: sanitizeDOM(description),
              saas: {
                licenseType,
                activePeriod: inputActivePeriod,
                activationLimit
              }
            }
          }
        });

        if (fileId && isDownloadable && !useRedirect) {
          const contentId =
            getUserContent.getMultipleFileFilteredList.length > 0
              ? getUserContent.getMultipleFileFilteredList[0].id
              : editPaymentLink?.data?.editPaymentLinkBySlug?.id;

          if (getUserContent.getMultipleFileFilteredList.length > 0) {
            await doUpdateUserContent({
              variables: {
                input: [
                  {
                    id: contentId,
                    value: fileId
                  }
                ]
              }
            });
          } else {
            await userUserContent({
              variables: {
                input: [
                  {
                    key: contentId,
                    value: fileId
                  }
                ]
              }
            });
          }
        }

        dispatch(updatePaymentLinks(Math.random()));
      } else {
        if (isDownloadable && !fileId && !useRedirect) return;

        const paymentLink = await insertPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              ...(isRemoveAllProductImage && { multipleImageId: null }),
              startAt,
              expiredAt: date,
              link: slug(values.name),
              type: 'saas',
              status: 'active',
              description: sanitizeDOM(description),
              saas: {
                licenseType,
                activePeriod: inputActivePeriod,
                activationLimit
              }
            }
          }
        });

        if (paymentLink && !useRedirect) {
          const paymentLinkId = paymentLink.data.insertPaymentLinkBySlug.id;
          await userUserContent({
            variables: {
              input: [
                {
                  key: paymentLinkId,
                  value: fileId
                }
              ]
            }
          });
        }

        //segment tracking
        window.analytics.track('Create ', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Saas Product - ERROR', values);
    }
  };

  if (newpl && newpl.insertPaymentLinkBySlug) onSuccess(newpl.insertPaymentLinkBySlug.id);
  if (upl && (userContent || updateUserContent)) onClose();

  if (dataFiles && dataFiles.getUserContents && dataFiles.getUserContents.length && !fileOptions.length) {
    const options = dataFiles.getUserContents.map(i => {
      return { value: i.id, label: i.filename };
    });
    setfileOptions(options);
  }

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Produk Berkode Lisensi</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Penjualan lisensi / serial number untuk produk software anda semakin mudah dengan Mayar karena mayar mampu
            mengenerate, validasi dan memberikan info kadaluarsa kodenya dengan API yang mudah
          </small>
        </p>

        <hr />

        {submitError.status && (
          <MayarAlert isError message={submitError.message} className="mb-10" toggle={resetError} />
        )}

        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Produk*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>

          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="licenseType">Tipe Lisensi*</Label>
            <Input
              id="licenseType"
              name="licenseType"
              type="select"
              value={licenseType}
              onChange={e => setLicenseType(e.target.value)}
            >
              <option>-- Pilih Tipe Lisensi--</option>
              {Object.keys(licenseTypeOptions).map(key => {
                return (
                  <option key={key} value={key}>
                    {licenseTypeOptions[key].value}
                  </option>
                );
              })}
            </Input>
          </FormGroup>

          {licenseType === 'PURCHASE_LIMITED' && (
            <FormGroup>
              <Label for="activePeriodDate">Masa Aktif*</Label>

              <div className="d-flex justify-content-between">
                <Input
                  id="activePeriodDate"
                  name="activePeriodDate"
                  type="number"
                  className="w-70 mr-5"
                  invalid={errors.activePeriodDate}
                  defaultValue={activePeriod[0]}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(1)
                  })}
                />

                <Input
                  id="activePeriod"
                  name="activePeriod"
                  type="select"
                  className="w-30"
                  defaultValue={activePeriod[1]}
                  innerRef={register({
                    required: validator.required
                  })}
                >
                  {Object.keys(periodOptions).map(period => (
                    <option key={period} value={period}>
                      {periodOptions[period]}
                    </option>
                  ))}
                </Input>
              </div>

              <FormFeedback>{errors.activePeriodDate?.message}</FormFeedback>
              <FormFeedback>{errors.actvePeriod?.message}</FormFeedback>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="activationLimit">Limit Aktivasi Kode</Label>
            <Input
              type="number"
              name="activationLimit"
              id="activationLimit"
              invalid={errors.activationLimit}
              defaultValue={data?.saasProductInfo?.activationLimit}
              innerRef={register()}
            />
            <FormFeedback>{errors.activationLimit?.message}</FormFeedback>
            <small>
              Kode lisensi akan inactive setelah melewati batas aktivasi dari limit yang telah ditentukan. Kosongkan
              untuk tanpa limit aktivasi (unlimited)
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="paymentType">Tipe Pembayaran</Label>
            <Controller
              name="paymentType"
              control={control}
              defaultValue={paymentType}
              render={({ onChange, name, value: defaultPaymentType }) => (
                <Select
                  id={name}
                  name={name}
                  options={paymentTypeOptions}
                  defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                  placeholder="Pilih Tipe Pembayaran"
                  styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setPaymentType(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>

          {paymentType === 'paid' && (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="amount">Harga*</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      invalid={errors.amount}
                      defaultValue={data.amount}
                      innerRef={register({
                        required: validator.required,
                        min: validator.min(1000),
                        max: validator.max(100_000_000)
                      })}
                    />
                    <FormFeedback>{errors.amount?.message}</FormFeedback>
                  </InputGroup>
                  <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="crossoutPrice">Harga Coret (opsional)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="crossoutPrice"
                      id="crossoutPrice"
                      defaultValue={data?.crossoutPrice}
                      invalid={errors.crossoutPrice}
                      innerRef={register({
                        min: validator.min(1000),
                        max: validator.max(100000000)
                      })}
                    />
                    <FormFeedback>{errors.crossoutPrice?.message}</FormFeedback>
                    <small>Harga coret harus lebih besar dari harga utama.</small>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}

          {paymentType === 'voluntary' && (
            <FormGroup>
              <Label for="amount">Minimal Harga</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={errors.amount}
                  placeholder="0+"
                  defaultValue={data.amount}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(0),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />

          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <DatePicker
                placeholder="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={startAt}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={value => setStartAt(value)}
              />
            </div>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="kadaluarsa4">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <DatePicker
                placeholder="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>

          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / QTY)</Label>
            <Input
              type="number"
              name="limit"
              id="limit"
              invalid={errors.limit}
              defaultValue={data.limit}
              innerRef={register()}
            />
            <FormFeedback>{errors.limit?.message}</FormFeedback>
            <small>
              Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
              jumlah (unlimited)
            </small>
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="isDownloadable"
              label="Ada file yang didownload setelah Beli?"
              name="isDownloadable"
              type="switch"
              color="primary"
              defaultChecked={data?.isDownloadable}
              onChange={e => setIsDownloadable(e.target.checked)}
              innerRef={register()}
            />
            <small>
              Jika ada, anda dapat mengupload filenya disini, atau anda bisa menyertakan url file untuk downloadnya
            </small>
          </FormGroup>

          {isDownloadable && useRedirect && (
            <FormGroup>
              <Label for="redirectUrl">Download URL</Label>
              <Input
                type="text"
                name="redirectUrl"
                id="redirectUrl"
                placeholder="https://websitesaya.com/file123.rar"
                invalid={Boolean(errors.redirectUrl)}
                defaultValue={data.redirectUrl}
                innerRef={register({ pattern: validator.url, required: validator.required })}
              />
              <FormFeedback>{errors.redirectUrl?.message}</FormFeedback>
              <small>Pelanggan akan dibawa kehalaman ini setelah membayar</small>
            </FormGroup>
          )}

          {isDownloadable && !useRedirect && (
            <>
              <FormGroup>
                <Label for="fileSource">Sumber File*</Label>
                <Input
                  type="select"
                  id="fileSource"
                  name="fileSource"
                  onChange={e => {
                    setSourceFile(e.target.value);
                  }}
                  invalid={Boolean(errors.fileSource)}
                  innerRef={register({ required: validator.required })}
                  value={sourceFile}
                >
                  <option value="">-- Pilih Sumber File --</option>
                  {fileOptions.length && <option value="existing">File Lama</option>}
                  <option value="new">Upload Baru</option>
                </Input>
                <FormFeedback>{errors.fileSource?.message}</FormFeedback>
              </FormGroup>

              {sourceFile === 'existing' && (
                <FormGroup>
                  <Label for="downloadFileId">Pilih File Lama*</Label>
                  {loadingFiles ? (
                    <span>
                      <LoadingAnimation twodashline />
                    </span>
                  ) : (
                    <Select
                      id="downloadFileId"
                      name="downloadFileId"
                      options={fileOptions}
                      styles={CustomStyles}
                      disabled={loadingFiles}
                      isLoading={loadingFiles}
                      defaultValue={
                        data && !data.redirectUrl && data.content
                          ? fileOptions.find(obj => obj.value === data?.content[0]?.file?.id)
                          : null
                      }
                      onChange={e => setFileId(e.value)}
                    />
                  )}
                  {!fileId && <FormFeedback className="error-block">File harus diisi</FormFeedback>}
                </FormGroup>
              )}

              {sourceFile === 'new' && (
                <FormGroup>
                  <Label for="images">File / Konten*</Label>
                  <Dropzone setFileId={setFileId} maxFiles={1} multiple={false} setNotAllowed={setNotAllowed} />
                  {!fileId && <FormFeedback className="error-block">File harus diisi</FormFeedback>}
                  {notAllowed && (
                    <FormFeedback className="error-block">
                      Nama file tidak boleh menggunakan special character!
                    </FormFeedback>
                  )}
                  <small>Ukuran file maksimal 1GB.</small>
                </FormGroup>
              )}
            </>
          )}

          {isDownloadable && (
            <FormGroup>
              <CustomInput
                id="useRedirect"
                label="Tidak Pakai File, Pakai Link Saja"
                name="useRedirect"
                type="switch"
                color="primary"
                defaultChecked={data?.redirectUrl}
                onChange={e => setUseRedirect(e.target.checked)}
                innerRef={register()}
              />
              <small>
                Jika menggunakan upload file, setelah membayar otomatis file akan didownload oleh pembeli. Jika
                menggunakan redirect URL, pelanggan akan dibawa ke halaman redirect URL setelah membayar
              </small>
            </FormGroup>
          )}

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Produk Berkode Lisensi`}
            loading
            isDisabled={disableSubmitButton}
            isLoading={insertLoading || updateLoading || insertUserContentLoading || updateUserContentLoading}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyLicenseCode;
