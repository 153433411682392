/**
 * External Dependencies
 */
import { combineReducers } from 'redux';
import Cookies from 'js-cookie';
import Omit from 'object.omit';

/**
 * Internal Dependencies
 */
import { getUID } from '../utils';
import defaultSettings from '../settings';
import { UPDATE_AUTH, UPDATE_SETTINGS, ADD_TOAST, REMOVE_TOAST, CHANGE_LOCATION } from '../actions';
import { selectProductsReducers } from './products';
import {
  customerFilterQReducers,
  customerUpdateReducers,
  customerFilterStatusReducers,
  selectCustomersReducers
} from './customers';
import {
  filterPaymentLinkByQReducer,
  UpdatePaymentLinksReducer,
  filterPaymentLinkByStatusReducer,
  paymentLinkDetailsReducer,
  paymentLinkFilterStartDateReducers,
  paymentLinkFilterEndDateReducers
} from './payment-links';
import {
  discountDetailsReducer,
  listProductsReducer,
  filterDiscountByQReducer,
  filterDiscountByStatusReducer,
  updateDiscountReducer,
  couponsDetailsReducer
} from './discount';
import { fundraisingSettingsReducer } from './fundraising';
import {
  filterSubscriptionByQReducer,
  UpdateSubscriptionsReducer,
  filterSubscriptionByStatusReducer,
  subscriptionDetailsReducer
} from './subscriptions';
import {
  filterSubscriptionCustomerByQReducer,
  UpdateSubscriptionCustomersReducer,
  filterSubscriptionCustomerByStatusReducer,
  subscriptionCustomerDetailsReducer
} from './subscription-customers';
import {
  filterMembershipByQReducer,
  UpdateMembershipsReducer,
  filterMembershipByStatusReducer,
  membershipPagesReducer
} from './memberships';
import { currentUserReducers, isHavingAccessToCreateProductReducer } from './users';
import { accountModeReducers } from './account-mode';
import {
  paymentFilterCustomerReducers,
  paymentFilterEndDateReducers,
  paymentFilterStartDateReducers,
  paymentFilterStatusReducers,
  paymentFilterTypeReducers
} from './payments';
import { isAddressRegisteredReducer } from './physical-product';
import {
  orderDetailsReducer,
  updateOrderReducer,
  filterOrderByCustomerReducer,
  filterOrderByStatusReducer,
  trackingOrderReducer
} from './order';
import { affiliateFilterQReducers, affiliateFilterStatusReducers } from './affiliate';
import { linkTreeTabReducer, linkTreeReducer } from './linktree';
import {
  UpdateLandingPagesReducer,
  filterLandingPageByQReducer,
  filterLandingPageByStatusReducer,
  landingPageDetailsReducer,
  UpdateLandingPagesThumbnailReducer,
  LandingPageCollectionsReducer,
  editorLandingTabReducer,
  editorContentIsChangedReducer
} from './landing-page';
import {
  updateBroadcastReducer,
  filterBroadcastByQReducer,
  filterBroadcastByStatusReducer,
  broadcastDetailsReducer
} from './broadcast';
import {
  updateBatchPaymentReducer,
  filterBatchPaymentByQReducer,
  filterBatchPaymentByStatusReducer,
  batchPaymentDetailsReducer,
  updateBatchPaymentHistoryReducer,
  filterBatchPaymentHistoryByQReducer,
  filterBatchPaymentHistoryByStatusReducer
} from './batch-payment';
import { courseSectionsReducer } from './course';
import { cohortSessionsReducer, cohortGradeUpdatedReducer } from './cohort-based';
import {
  balanceFilterStartDateReducers,
  balanceFilterEndDateReducers,
  balanceFilterPickupStartDateReducers,
  balanceFilterPickupEndDateReducers
} from './balance-history';
import {
  employeeFilterQReducers,
  employeeUpdateReducers,
  employeeFilterStatusReducers,
  selectEmployeeReducers
} from './employee';
import {
  filterWebhookHistoryByQReducer,
  updateWebhookHistoryReducer,
  filterWebhookHistoryByStatusReducer,
  webhookHistoryDetailsReducer
} from './webhook';
import { supportDetailsReducer, supportTabReducer } from './support';
import { landingPromotionReducer } from './landing-promotion';
import { formKycFillReducer } from './setting';
import { updateTrackReducer } from './track';
import { ticketDetailsReducer } from './event';
import { updateNameServiceReducer } from './name-service';

// initial state.
const INITIAL_SETTINGS_STATE = {
  ...defaultSettings
};
const INITIAL_AUTH_STATE = {
  token: Cookies.get('token')
};
const INITIAL_TOASTS_STATE = [];
const INITIAL_LOCATION_STATE = [];
/**
 * Reducer
 */
const appReducer = combineReducers({
  auth: (state = INITIAL_AUTH_STATE, action) => {
    switch (action.type) {
      case UPDATE_AUTH:
        // save token to cookies for 3 days.
        if (typeof action.auth.token !== 'undefined') {
          Cookies.set('token', action.auth.token, { expires: 3 });
        }

        return Object.assign({}, state, action.auth);
      default:
        return state;
    }
  },
  settings: (state = INITIAL_SETTINGS_STATE, action) => {
    switch (action.type) {
      case UPDATE_SETTINGS:
        return Object.assign({}, state, action.settings);
      default:
        return state;
    }
  },
  toasts: (state = INITIAL_TOASTS_STATE, action) => {
    switch (action.type) {
      case ADD_TOAST:
        const newData = {
          ...{
            title: '',
            content: '',
            color: 'brand',
            time: false,
            duration: 0,
            closeButton: true
          },
          ...action.data
        };

        if (newData.time === true) {
          newData.time = new Date();
        }

        return {
          ...state,
          [getUID()]: newData
        };
      case REMOVE_TOAST:
        if (!action.id || !state[action.id]) {
          return state;
        }
        return Omit(state, action.id);
      default:
        return state;
    }
  },
  location: (state = INITIAL_LOCATION_STATE, action) => {
    switch (action.type) {
      case CHANGE_LOCATION:
        return Object.assign({}, state, action.payload);
      default:
        return state;
    }
  },

  // Global
  accountMode: accountModeReducers,

  currentUser: currentUserReducers,
  customerFilterQ: customerFilterQReducers,
  customerFilterStatus: customerFilterStatusReducers,
  customerUpdate: customerUpdateReducers,
  selectCustomers: selectCustomersReducers,
  filterStartDate: paymentFilterStartDateReducers,
  filterEndDate: paymentFilterEndDateReducers,
  formKycFill: formKycFillReducer,

  // product
  selectProducts: selectProductsReducers,

  // payment link
  filterPaymentLinkByQ: filterPaymentLinkByQReducer,
  updatePaymentLinks: UpdatePaymentLinksReducer,
  filterPaymentLinkByStatus: filterPaymentLinkByStatusReducer,
  paymentLinkDetails: paymentLinkDetailsReducer,
  paymentLinkStartDate: paymentLinkFilterStartDateReducers,
  paymentLinkEndDate: paymentLinkFilterEndDateReducers,

  // discount
  discountDetails: discountDetailsReducer,
  listProducts: listProductsReducer,
  filterDiscountByQ: filterDiscountByQReducer,
  filterDiscountByStatus: filterDiscountByStatusReducer,
  updateDiscount: updateDiscountReducer,
  couponsDetails: couponsDetailsReducer,

  // fundraising
  fundraisingSettings: fundraisingSettingsReducer,

  // subscription
  filterSubscriptionByQ: filterSubscriptionByQReducer,
  updateSubscriptions: UpdateSubscriptionsReducer,
  filterSubscriptionByStatus: filterSubscriptionByStatusReducer,
  subscriptionDetails: subscriptionDetailsReducer,

  // subscription customer
  filterSubscriptionCustomerByQ: filterSubscriptionCustomerByQReducer,
  updateSubscriptionCustomers: UpdateSubscriptionCustomersReducer,
  filterSubscriptionCustomerByStatus: filterSubscriptionCustomerByStatusReducer,
  subscriptionCustomerDetails: subscriptionCustomerDetailsReducer,

  // membership
  filterMembershipByQ: filterMembershipByQReducer,
  updateMemberships: UpdateMembershipsReducer,
  filterMembershipByStatus: filterMembershipByStatusReducer,
  membershipPages: membershipPagesReducer,

  // payment
  paymentFilterCustomer: paymentFilterCustomerReducers,
  paymentFilterStatus: paymentFilterStatusReducers,
  paymentFilterType: paymentFilterTypeReducers,

  // physical product
  isAddressRegistered: isAddressRegisteredReducer,

  // order
  orderDetails: orderDetailsReducer,
  updateOrder: updateOrderReducer,
  orderFilterCustomer: filterOrderByCustomerReducer,
  orderFilterStatus: filterOrderByStatusReducer,
  trackingOrder: trackingOrderReducer,

  // affiliate
  affiliateFilterByQ: affiliateFilterQReducers,
  affiliateFilterByStatus: affiliateFilterStatusReducers,

  // linktree
  linkTreeTab: linkTreeTabReducer,
  linkTree: linkTreeReducer,

  // landing page
  updateLandingPages: UpdateLandingPagesReducer,
  filterLandingPageByQ: filterLandingPageByQReducer,
  filterLandingPageByStatus: filterLandingPageByStatusReducer,
  landingPageDetails: landingPageDetailsReducer,
  updateLandingPagesThumbnail: UpdateLandingPagesThumbnailReducer,
  landingPageCollections: LandingPageCollectionsReducer,
  editorLandingTab: editorLandingTabReducer,
  editorContentIsChanged: editorContentIsChangedReducer,

  // broadcast
  updateBroadcast: updateBroadcastReducer,
  filterBroadcastByQ: filterBroadcastByQReducer,
  filterBroadcastByStatus: filterBroadcastByStatusReducer,
  broadcastDetails: broadcastDetailsReducer,

  // batch payment
  updateBatchPayment: updateBatchPaymentReducer,
  filterBatchPaymentByStatus: filterBatchPaymentByStatusReducer,
  batchPaymentDetails: batchPaymentDetailsReducer,
  filterBatchPaymentByQ: filterBatchPaymentByQReducer,
  updateBatchPaymentHistory: updateBatchPaymentHistoryReducer,
  filterBatchPaymenthHistoryByStatus: filterBatchPaymentHistoryByStatusReducer,
  filterBatchPaymentHistoryByQ: filterBatchPaymentHistoryByQReducer,

  // course
  courseSections: courseSectionsReducer,

  // cohort-based
  cohortSessions: cohortSessionsReducer,
  cohortGradeUpdated: cohortGradeUpdatedReducer,

  // subaccount
  isHavingAccessToCreateProduct: isHavingAccessToCreateProductReducer,

  // balance history
  filterBalanceStartDate: balanceFilterStartDateReducers,
  filterBalanceEndDate: balanceFilterEndDateReducers,
  filterPickupStartDate: balanceFilterPickupStartDateReducers,
  filterPickupEndDate: balanceFilterPickupEndDateReducers,

  // employee
  employeeFilterQ: employeeFilterQReducers,
  employeeFilterStatus: employeeFilterStatusReducers,
  employeeUpdate: employeeUpdateReducers,
  selectEmployee: selectEmployeeReducers,

  // Webhook history
  updateWebhookHistory: updateWebhookHistoryReducer,
  filterWebhookHistoryByQ: filterWebhookHistoryByQReducer,
  filterWebhookHistoryByStatus: filterWebhookHistoryByStatusReducer,
  webhookHistoryDetails: webhookHistoryDetailsReducer,

  // Support
  supportTab: supportTabReducer,
  supportDetails: supportDetailsReducer,

  // Landing Promotion
  landingPromotion: landingPromotionReducer,

  // Track
  updateTrack: updateTrackReducer,

  // Event
  ticketDetails: ticketDetailsReducer,

  // Name Service / Plugins Store
  updateNameService: updateNameServiceReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
