import { gql } from '@apollo/client';

export const GQL_PAYMENTLINK_GET_WITH_USER = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        amount
        createdAt
        customerId
        description
        expiredAt
        limit
        link
        status
        name
        redirectUrl
        subscriptionId
        type
        updatedAt
        userId
        customer {
          name
          email
        }
        user {
          name
          email
        }
      }
    }
  }
`;

// Query for payment link in detail page
export const GET_PAYMENT_LINK_DETAIL = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        status
        amount
        crossoutPrice
        description
        limit
        startAt
        expiredAt
        notes
        redirectUrl
        createdAt
        link
        paymentType
        type
        reminderWellcomeId
        urlParamTracking
        variant
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        content {
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
        }
        multipleImage {
          url
          updatedAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
          recommendedAmount
          isPopupRecentTransaction
        }
      }
    }
  }
`;

export const GQL_PAYMENTLINK_GET = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        formId
        urlParamTracking
        form {
          fieldObject
          id
          name
        }
        multipleImage {
          url
        }
        event {
          id
          eventAddress
          maxTicket
          isOnlineEvent
          ticketEvent {
            id
            name
            type
            amount
            ticketStartDate
            ticketEndDate
            description
            quantity
            isVisible
          }
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
        }
        metadata {
          ISBN
          author
          duration
          format
          language
          pages
          publishedAt
        }
        oneAccountTransaction
        target
        reason
        receiver
        usage
        items
        isDownloadable
        tax
        category
        amount
        createdAt
        customerId
        description
        expiredAt
        limit
        link
        status
        name
        redirectUrl
        subscriptionId
        paymentType
        subType
        qty
        type
        updatedAt
        userId
        reminderWellcomeId
        user {
          account {
            name
          }
        }
        writtenBy
        readBy
        transcript
        eventStartAt
        eventEndAt
        terms
        eventUrl
        notes
        fundraisingSettings
        coverImage {
          url
          id
        }
        multipleImage {
          url
          updatedAt
        }
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        customer {
          name
          email
        }
        bundlingProductType
        bundlingPaymentLinks {
          status
          variantId
          paymentLink {
            id
            name
            type
            amount
            variant
          }
        }
      }
    }
  }
`;

export const GQL_PAYMENTLINK_GET_WITH_TRANSACTIONS = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        formId
        urlParamTracking
        form {
          fieldObject
          id
          name
        }
        multipleImage {
          url
        }
        certificate {
          id
          layout
          name
          templateImageId
          templateImage {
            id
            url
            title
          }
        }
        event {
          id
          eventAddress
          mapLocation
          maxTicket
          isOnlineEvent
          ticketEvent {
            id
            name
            type
            amount
            ticketStartDate
            ticketEndDate
            description
            quantity
            isVisible
          }
        }
        oneAccountTransaction
        target
        reason
        receiver
        usage
        items
        tax
        category
        amount
        createdAt
        customerId
        description
        expiredAt
        limit
        link
        status
        name
        redirectUrl
        subscriptionId
        paymentType
        subType
        qty
        type
        qurbanType
        updatedAt
        userId
        user {
          id
          account {
            name
          }
        }
        startAt
        eventStartAt
        eventEndAt
        terms
        eventUrl
        notes
        fundraisingSettings
        fundraisingUsagePlan
        coverImage {
          url
          id
        }
        multipleImage {
          url
          updatedAt
        }
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        customer {
          name
          email
        }
        transactions {
          id
          amount
          createdAt
          customerId
          paymentLinkDescription
          paymentLinkId
          paymentLinkName
          status
          updatedAt
          customer {
            name
            email
          }
        }
        installment {
          id
          dueDate
          interest
          interestType
          period
          tenure
          invoices {
            id
            status
            updatedAt
          }
        }
      }
    }
  }
`;

export const GQL_PAYMENTLINK_PAGE_WITH_CUSTOMER = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        amount
        createdAt
        customerId
        description
        expiredAt
        limit
        status
        link
        name
        redirectUrl
        subscriptionId
        type
        updatedAt
        userId
        index
        remainingAmount
        interestAmount
        customer {
          name
          email
        }
        installment {
          id
          dueDate
          interest
          interestType
          period
          tenure
          invoices {
            id
            name
            expiredAt
            status
          }
        }
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_PAYMENTLINK_KBAR = gql`
  query getPaymentLinkFilteredList($limit: Int, $search: PaymentLinkSearchInput) {
    getPaymentLinkFilteredList(limit: $limit, search: $search) {
      createdAt
      limit
      name
      type
      id
    }
  }
`;

export const GET_PAYMENT_LINK_PARAM_TRACKING = gql`
  query getPaymentLinkFilteredList($limit: Int, $search: PaymentLinkSearchInput) {
    getPaymentLinkFilteredList(limit: $limit, search: $search) {
      urlParamTracking
      isNoTrackingValidation
    }
  }
`;

// query payment link for basic table
export const GET_PAYMENT_LINK_TABLE = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        link
        name
        amount
        status
        createdAt
        writingType
        writingPaymentMethod
      }
      nextPage
    }
  }
`;

export const GET_PAYMENT_LINK = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        link
        name
        amount
        status
        createdAt
      }
      total
    }
  }
`;

export const GQL_PAYMENTLINK_PAGE = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        amount
        createdAt
        customerId
        description
        expiredAt
        limit
        status
        link
        name
        redirectUrl
        subscriptionId
        paymentType
        type
        updatedAt
        userId
        qty
        subType
        order {
          variant
        }
        transactions {
          amount
          status
          paymentLinkName
        }
      }
      nextPage
    }
  }
`;

export const GQL_PAYMENTLINK_PAGE_TOTAL = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_PAYMENTLINK_CREATE = gql`
  mutation insertPaymentLink($input: PaymentLinkInput) {
    insertPaymentLink(input: $input) {
      id
      amount
      customerId
      description
      expiredAt
      limit
      link
      status
      type
      name
      redirectUrl
      subscriptionId
      type
      customer {
        email
        mobile
        name
      }
    }
  }
`;

export const GQL_PAYMENTLINK_CREATE_BY_SLUG = gql`
  mutation insertPaymentLinkBySlug($input: PaymentLinkInput!) {
    insertPaymentLinkBySlug(input: $input) {
      id
      isDownloadable
      amount
      customerId
      description
      expiredAt
      limit
      link
      status
      type
      name
      redirectUrl
      subscriptionId
      type
      customer {
        email
        mobile
        name
      }
      installment {
        id
      }
    }
  }
`;

export const GQL_METADATA_CREATE = gql`
  mutation insertMetadata($input: MetadataInput) {
    insertMetadata(input: $input) {
      ISBN
      author
      createdAt
      duration
      format
      language
      pages
      paymentLinkId
      publishedAt
      title
      updatedAt
      userId
    }
  }
`;

export const UPDATE_PAYMENT_LINK = gql`
  mutation editPaymentLinkBySlug($input: PaymentLinkInput!) {
    editPaymentLinkBySlug(input: $input) {
      id
      variant
      errorMessage
    }
  }
`;

export const DUPLICATE_PAYMENT_LINK = gql`
  mutation duplicatePaymentLink($id: ID!) {
    duplicatePaymentLink(id: $id) {
      id
    }
  }
`;

export const GQL_PAYMENTLINK_UPDATE_BY_SLUG = gql`
  mutation editPaymentLinkBySlug($input: PaymentLinkInput!) {
    editPaymentLinkBySlug(input: $input) {
      id
      isDownloadable
      amount
      customerId
      description
      expiredAt
      limit
      link
      name
      redirectUrl
      subscriptionId
      type
      readBy
      writtenBy
    }
  }
`;

export const UPDATE_PL_BY_SLUG = gql`
  mutation editPaymentLinkBySlug($input: PaymentLinkInput!) {
    editPaymentLinkBySlug(input: $input) {
      id
      isDownloadable
      amount
      customerId
      description
      expiredAt
      limit
      link
      name
      redirectUrl
      subscriptionId
      type
      readBy
      writtenBy
    }
  }
`;

export const GQL_PAYMENTLINK_DELETE = gql`
  mutation deletePaymentLink($id: ID) {
    deletePaymentLink(id: $id)
  }
`;

export const GQL_PAYMENTLINK_SELECT = gql`
  query getPaymentLinkFilteredList(
    $search: PaymentLinkSearchInput
    $searchAny: PaymentLinkSearchInput
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $limit: Int
  ) {
    getPaymentLinkFilteredList(
      search: $search
      searchAny: $searchAny
      limit: $limit
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      id
      link
      name
      type
      subType
    }
  }
`;

export const GET_TOTAL_PLT = gql`
  query getPaymentLinkTransactionPage($search: PaymentLinkTransactionSearchInput) {
    getPaymentLinkTransactionPage(search: $search) {
      total
    }
  }
`;

export const GQL_GET_TOTAL_PAYMENT_LINK_TRANSACTION = gql`
  query getTotalPaymentLinkTransaction($search: PaymentLinkTransactionSearchInput!) {
    getTotalPaymentLinkTransaction(search: $search) {
      total
    }
  }
`;

export const GET_TOTAL_PLT_WITH_ACTIVE_CUSTOMER = gql`
  query getTotalPLTActiveCustomer(
    $search: PaymentLinkTransactionSearchInput!
    $searchAny: PaymentLinkTransactionSearchInput
  ) {
    getTotalPLTActiveCustomer(search: $search, searchAny: $searchAny) {
      total
    }
  }
`;

export const GQL_FUNDRAISING_DATA_PROXY = gql`
  query getFundraisingData(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $search: PaymentLinkSearchInput
    $searchAny: PaymentLinkSearchInput
  ) {
    getFundraisingData(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
      searchAny: $searchAny
    ) {
      id
      name
      status
      createdAt
      link
      totalFundraising
      subType
    }
  }
`;

export const GET_PAYMENT_LINK_TRANSACTION_BY_KEYWORD = gql`
  query getPaymentLinkTransactionByKeyword($keyword: String!, $paymentLinkId: ID!) {
    getPaymentLinkTransactionByKeyword(keyword: $keyword, paymentLinkId: $paymentLinkId) {
      id
      invoiceCode
      customer {
        email
        name
      }
    }
  }
`;

// replacement of GQL_PAYMENTLINK_GET_WITH_TRANSACTIONS
export const GET_PL_WITH_TRANSACTIONS = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        link
        status
        description
        eventUrl
        startAt
        eventStartAt
        eventEndAt
        amount
        crossoutPrice
        limit
        expiredAt
        notes
        redirectUrl
        createdAt
        formId
        type
        terms
        reminderWellcomeId
        paymentType
        urlParamTracking
        variant
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        certificate {
          id
          layout
          name
          templateImageId
          startAt
          blastEmailAt
          templateImage {
            id
            url
            title
          }
        }
        multipleImage {
          url
          id
          createdAt
          updatedAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
          recommendedAmount
          isPopupRecentTransaction
        }
        user {
          id
          account {
            name
          }
        }
      }
    }
  }
`;

// replacement of GQL_PAYMENTLINK_GET_WITH_TRANSACTIONS for event
export const GET_PL_WITH_TRANSACTIONS_EVENT = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        link
        status
        description
        eventUrl
        startAt
        eventStartAt
        eventEndAt
        amount
        limit
        expiredAt
        notes
        redirectUrl
        createdAt
        terms
        paymentType
        type
        formId
        reminderWellcomeId
        urlParamTracking
        oneAccountTransaction
        ticketImage {
          title
          url
        }
        event {
          id
          eventAddress
          maxTicket
          isOnlineEvent
          ticketEvent {
            id
            name
            type
            amount
            ticketStartDate
            ticketEndDate
            description
            quantity
            isVisible
          }
        }
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        certificate {
          id
          layout
          name
          startAt
          blastEmailAt
          templateImageId
          templateImage {
            id
            url
            title
          }
        }
        multipleImage {
          url
          id
          createdAt
          updatedAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
          isPopupRecentTransaction
        }
        user {
          id
          account {
            name
          }
        }
      }
    }
  }
`;

// replacement of GQL_PAYMENTLINK_GET for ebook | podcast | audiobook detail page
export const GET_PAYMENT_LINK_COMMERCE = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        link
        status
        isDownloadable
        amount
        crossoutPrice
        description
        limit
        startAt
        expiredAt
        notes
        redirectUrl
        createdAt
        formId
        paymentType
        type
        reminderWellcomeId
        urlParamTracking
        variant
        writingType
        writingPaymentMethod
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        multipleImage {
          url
          updatedAt
        }
        metadata {
          ISBN
          author
          duration
          format
          language
          pages
          publishedAt
        }
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
          recommendedAmount
          isPopupRecentTransaction
        }
      }
    }
  }
`;

export const GET_PAYMENT_LINK_WRITING = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        link
        status
        amount
        description
        limit
        startAt
        expiredAt
        notes
        redirectUrl
        createdAt
        formId
        paymentType
        type
        reminderWellcomeId
        urlParamTracking
        userId
        writingType
        writingPaymentMethod
        writingChapters {
          id
          title
        }
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        multipleImage {
          url
          updatedAt
        }
        metadata {
          author
          format
          language
          pages
          publishedAt
          genreId
          genre {
            name
          }
        }
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
          isSupportGift
          recommendedAmount
          isPopupRecentTransaction
        }
      }
    }
  }
`;

export const EDIT_PAYMENT_LINK = gql`
  mutation editPaymentLink($input: PaymentLinkInput) {
    editPaymentLink(input: $input) {
      id
    }
  }
`;

export const GET_PRODUCT_RECORDING = gql`
  query getCourseModuleFilteredList($search: CourseModuleSearchInput, $limit: Int) {
    getCourseModuleFilteredList(search: $search, limit: $limit) {
      id
      embedUrl
      type
      name
      isContentNotProtected
      description
    }
  }
`;
