const defaultConfig = [
  {
    label: 'Akun',
    labelKey: 'akun',
    name: 'Lihat Saldo dan Histori Saldo',
    route: ['/balance', '/balance-history'],
    sidebarName: ['Saldo', 'Histori', 'Penarikan & Top Up'],
    scope: ['admin', 'finance']
  },
  {
    name: 'Request Pencairan',
    labelKey: 'akun',
    scope: ['admin', 'finance']
  },
  {
    name: 'Lihat Analytics',
    labelKey: 'akun',
    route: ['/analytics-overview', '/detail-analytics'],
    sidebarName: ['Analytics', 'Overview', 'Detail Analytics'],
    scope: ['admin', 'marketing']
  },
  {
    name: 'Edit Detail Bisnis',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'account',
    scope: ['admin']
  },
  {
    name: 'Edit Channel Pembayaran',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'channel',
    scope: ['admin', 'finance']
  },
  {
    name: 'Edit Alamat Pengiriman',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'shipping',
    scope: ['admin']
  },
  {
    name: 'Edit Rekening',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'bank-acc',
    scope: ['admin']
  },
  {
    name: 'Edit Kustomisasi',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'custom',
    scope: ['admin']
  },
  {
    name: 'Edit Team',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'team',
    scope: ['admin']
  },
  {
    name: 'Edit Email Notifikasi',
    labelKey: 'akun',
    route: ['/settings'],
    settingRoute: 'email',
    scope: ['admin']
  },
  {
    name: 'Akses Developer',
    labelKey: 'akun',
    route: ['/api-keys', '/webhook', '/applications'],
    sidebarName: ['Integrasi', 'API Keys & Token', 'Webhook', 'Aplikasi'],
    scope: ['admin']
  },
  {
    label: 'General',
    labelKey: 'general',
    name: 'Buat / Edit Pelanggan',
    sidebarName: ['Pelanggan'],
    route: ['/customers', '/customer-detail/:id'],
    scope: ['admin', 'operation', 'finance', 'marketing']
  },
  {
    name: 'Buat / Edit Invoice dan Permintaan Pembayaran',
    labelKey: 'general',
    sidebarName: ['Invoice', 'Penagihan', 'Permintaan Pembayaran', 'Cicilan'],
    route: [
      '/single-payment-requests',
      '/invoices',
      '/installments',
      '/invoice-detail/:id',
      '/payment-request-detail/:id',
      '/installment-detail/:id'
    ],
    scope: ['admin', 'finance', 'operation']
  },
  {
    name: 'Lihat Pelanggan',
    labelKey: 'general',
    sidebarName: ['Pelanggan'],
    route: ['/customers', '/customer-detail/:id'],
    scope: ['admin', 'finance', 'marketing', 'operation']
  },
  {
    name: 'Lihat Transaksi',
    labelKey: 'general',
    route: ['/payments', '/payment-detail/:id', '/unpaid-transactions'],
    sidebarName: ['Transaksi'],
    scope: ['admin', 'finance', 'marketing', 'operation']
  },
  {
    name: 'Lihat Berlangganan',
    labelKey: 'general',
    route: ['/recurring-payments', '/recurring-payment-detail/:id'],
    sidebarName: ['Berlangganan'],
    scope: ['admin', 'finance', 'marketing', 'operation']
  },
  {
    name: 'Export Data',
    labelKey: 'general',
    scope: ['admin', 'operation', 'finance']
  },
  {
    label: 'Marketing',
    labelKey: 'marketing',
    name: 'Buat / Edit Marketing',
    sidebarName: [
      'Storefront',
      'Broadcast',
      'Segmen Pelanggan',
      'Auto Responder',
      'Link Bio & Katalog',
      'Landing Page',
      'Simple Landing Page',
      'Advance Landing Page',
      'Affiliate',
      'Anggota Affiliates',
      'Sebagai Affiliator',
      'Diskon dan Kupon',
      'Bundling'
    ],
    route: [
      '/storefront',
      '/broadcasts',
      '/broadcast-detail/:id',
      '/broadcast-email-editor/:id',
      '/customer-segment',
      '/autoresponders',
      '/homepage',
      '/landing-page-overview',
      '/simple-landing-pages',
      '/landing-pages',
      '/landing-page-editor/:id',
      '/landing-page-detail/:id',
      '/simple-landing-pages',
      '/simple-landing-page-editor/:id',
      '/simple-landing-page-detail/:id',
      '/affiliates',
      '/affiliate-detail/:id',
      '/affiliate-register/:id',
      '/as-affiliate',
      '/affiliate-product-detail/:id',
      '/diskon',
      '/discount-detail/:id',
      '/bundling',
      '/bundling-detail/:id'
    ],
    scope: ['admin', 'marketing']
  },
  {
    label: 'Produk',
    labelKey: 'produk',
    name: 'Buat / Edit Produk',
    sidebarName: [
      'Link Pembayaran',
      'Produk Fisik',
      'Produk Digital',
      'Kelas Online (OD)',
      'Kelas Cohort / Bootcamp',
      'Webinar',
      'Event & Acara',
      'Coaching & Mentoring',
      'Penggalangan Dana',
      'Paket Berlangganan',
      'E-Book',
      'Podcast',
      'Audio Book',
      'Membership & SaaS',
      'Tulisan',
      'Lisensi Software'
    ],
    route: [
      '/payment-link',
      '/payment-link-detail/:id',
      '/physical-products',
      '/physical-product-detail/:id',
      '/digital-products',
      '/digital-product-detail/:id',
      '/online-courses',
      '/online-course-detail/:id',
      '/cohort-courses',
      '/cohort-course-detail/:id',
      '/webinars',
      '/webinar-detail/:id',
      '/events',
      '/event-detail/:id',
      '/coachings',
      '/coaching-detail/:id',
      '/fundraisings',
      '/fundraising-detail/:id',
      '/qurban-detail/:id',
      '/waqaf-detail/:id',
      '/payment-plans',
      '/payment-plan-detail/:id',
      '/ebooks',
      '/ebook-detail/:id',
      '/podcasts',
      '/podcast-detail/:id',
      '/audiobooks',
      '/audiobook-detail/:id',
      '/memberships',
      '/membership-detail/:id',
      '/writings',
      '/writing-detail/:id',
      '/license-codes',
      '/license-code-detail/:id'
    ],
    // checked: true,
    scope: ['admin', 'marketing', 'operation']
  },
  {
    labelKey: 'produk',
    name: 'Check-in Event',
    scope: ['admin', 'operation']
  },
  {
    name: 'Lihat Transaksi Produk',
    labelKey: 'produk',
    sidebarName: [
      'Link Pembayaran',
      'Produk Fisik',
      'Produk Digital',
      'Kelas Online (OD)',
      'Kelas Cohort / Bootcamp',
      'Webinar',
      'Event & Acara',
      'Coaching & Mentoring',
      'Penggalangan Dana',
      'Paket Berlangganan',
      'E-Book',
      'Podcast',
      'Audio Book',
      'Membership & SaaS',
      'Tulisan',
      'Lisensi Software'
    ],
    route: [
      '/payment-link',
      '/payment-link-detail/:id',
      '/physical-products',
      '/physical-product-detail/:id',
      '/digital-products',
      '/digital-product-detail/:id',
      '/online-courses',
      '/online-course-detail/:id',
      '/cohort-courses',
      '/cohort-course-detail/:id',
      '/webinars',
      '/webinar-detail/:id',
      '/events',
      '/event-detail/:id',
      '/coachings',
      '/coaching-detail/:id',
      '/fundraisings',
      '/fundraising-detail/:id',
      '/qurban-detail/:id',
      '/waqaf-detail/:id',
      '/payment-plans',
      '/payment-plan-detail/:id',
      '/ebooks',
      '/ebook-detail/:id',
      '/podcasts',
      '/podcast-detail/:id',
      '/audiobooks',
      '/audiobook-detail/:id',
      '/memberships',
      '/membership-detail/:id',
      '/writings',
      '/writing-detail/:id',
      '/license-codes',
      '/license-code-detail/:id'
    ],
    scope: ['admin', 'marketing', 'operation', 'finance']
  },
  {
    name: 'Proses Order',
    labelKey: 'produk',
    route: ['/orders', '/order-detail/:id'],
    sidebarName: ['Order'],
    scope: ['admin', 'operation']
  }
];

export default defaultConfig;
