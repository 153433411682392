import { gql } from '@apollo/client';

export const GQL_INSERT_CERTIFICATE = gql`
  mutation insertCertificate($input: CertificateInput) {
    insertCertificate(input: $input) {
      id
      layout
      name
      templateImageId
      templateImage {
        id
        url
        title
      }
    }
  }
`;

export const GQL_EDIT_CERTIFICATE = gql`
  mutation editCertificate($input: CertificateInput) {
    editCertificate(input: $input) {
      id
      layout
      name
      templateImageId
      templateImage {
        id
        url
        title
      }
    }
  }
`;

export const GQL_SEND_CERTIFICATE = gql`
  mutation sendMailCertificate($input: CertificateInput!) {
    sendMailCertificate(input: $input) {
      id
      name
      status
      message
    }
  }
`;
